.css-2b097c-container {
  margin-bottom: 15px;
}

.css-yk16xz-control {
  min-height: 48px !important;
  border-radius: 1px !important;
  max-height: 48px !important;
  overflow: auto !important;
}

.css-1pahdxg-control {
  min-height: 48px !important;
  border-radius: 1px !important;
  border-color: rgb(204, 204, 204) !important;
  max-height: 48px !important;
  overflow: auto !important;
}

.css-1pahdxg-control:hover {
  min-height: 48px !important;
  border-radius: 1px !important;
  border-color: rgb(204, 204, 204) !important;
  max-height: 48px !important;
  overflow: auto !important;
}
