.title-display {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.accordion-body {
  padding: 0px !important;
}

.accordion-header {
  display: flex;
  align-items: center;
}

.file-nosku-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: medium;
}

.mapping_manage_container:global(.container-fluid) ol {
  padding-left: 0px !important;
}

.mapping_management_accordion :global(.accordion-body) {
  padding: 0px !important;
}

.mapping_management_accordion :global(.accordion-header) {
  display: flex;
  align-items: center;
}

.mapping_management_accordion :global(.fa-lg) {
  font-size: 2.5em;
}

.mapping_management_accordion_actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.mapping_management_title_display {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.file_no_sku_message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: medium;
}

.modalStyle:global(.modal-dialog) :global(.modal-body) {
  overflow-y: visible;
}

.mappingTable:global(.ReactTable > .rt-table .rt-th:not(:last-child)) {
  border-right: 0px;
}

.mappingSearch > div {
  margin: 0px 16px 0px 0px;
  width: 100%;
}

.scrapedFileActionsNavigationIcon :global(.btn.btn-link),
.mappingActionsNavigationIcon :global(.btn.btn-link) {
  width: 29px;
  height: 29px;
  padding: unset;
}

.scrapedFileActionsNavigationIcon :global(.dropdown-menu),
.mappingActionsNavigationIcon :global(.dropdown-menu) {
  right: 0;
  left: unset;
}
