.draggable {
  border-radius: 8px;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 15px 24px;
  border: 1.4px solid #0088a9;
  font-weight: 600;
  color: #0088a9;
  z-index: 10;
  background: #fff;
  text-shadow: none;
  display: inline-block;
  margin-bottom: 0;
  white-space: wrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  line-height: 1.286;
  box-shadow: 1px 1px 1px 1px #dbdce0;
}
div.draggable:hover {
  background: #b0d9e6;
}

.item-selected {
  color: white;
  background-color: #4095bf;
}
