.flex-container {
  display: flex;
  flex-wrap: nowrap;
}
.leftdiv {
  margin: 0px;
  width: 100%;
}

.rightdiv {
  margin: 0px;
  width: 0%;
}
.rt-tr-group:hover {
  background: #eff3f5;
}
.pagination-bottom {
  padding: 10px;
  z-index: 10;
}
.rt-table {
  border: none !important;
}
div.spinner {
  z-index: 2342;
}
disable-div {
  pointer-events: none;
  opacity: 0.7;
}
.inner-content {
  background-color: #fff;
  min-height: 65vh;
  padding: 15px 15px 60px !important;
  margin-bottom: 30px !important;
}
.css-12h9q4t {
  align-items: flex-end;
  display: flex;
  margin-bottom: 10px;
}
.css-i9gxme {
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.minwidth {
  min-width: 100px !important;
}

.bg-transparent {
  background-color: #fff !important;
  min-width: 35px !important;
}

.input-group-new {
  position: relative !important;
  display: -ms-flexbox !important;
  display: flex !important;

  -ms-flex-align: stretch !important;
  align-items: stretch !important;
  width: 100% !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.input-group-new-append {
  display: -ms-flexbox;
  display: flex;
}

.input-group-new-append {
  margin-left: -1px;
}

.input-group-new-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-new
  > .input-group-new-append:not(:last-child)
  > .input-group-new-text,
.input-group-new
  > .input-group-new-append:last-child
  > .input-group-new-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-new > .input-group-new-append > .input-group-new-text,
.input-group-new
  > .input-group-new-prepend:first-child
  > .input-group-new-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.py-2 {
  padding-top: 0.5rem !important;
}
.py-2 {
  padding-bottom: 0.5rem !important;
}

.toggle .toggle-switch {
  width: 30px;
  height: 30px;
  margin: 4px !important;
  background: #fff !important;
  border-radius: 2px;
}
.questionnaire-padding {
  padding-bottom: 10px;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.selected-attribute-buttons {
  display: flex;
  justify-content: flex-end;
}

.always-copy {
  margin-top: 10px;
}

.copy-button {
  display: flex;
  flex-direction: column;
}

.remove-right-border .btn.btn-default {
  border: solid #0088a9 !important;
  border-top-width: 1px !important;
  border-right-width: 0px !important;
  border-left-width: 1px !important;
  border-bottom-width: 1px !important;
}

.remove-complete-radius .btn.btn-default {
  border-radius: 0% !important;
}

.remove-left-radius .btn.btn-default {
  border-bottom-left-radius: 0% !important;
  border-top-left-radius: 0% !important;
}

.remove-right-radius .btn.btn-default {
  border-bottom-right-radius: 0% !important;
  border-top-right-radius: 0% !important;
}
