.breadcrumb-separator {
    color: #333;
    margin: auto 6px;
    user-select: none;
  }

  ol {
    list-style: none;
    display: flex;
    align-items: center;
    font-size: .786em
  }

  