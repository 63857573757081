.questionnaire_breadcrumb ol {
  padding-left: unset;
}

.questionnaire_header:global(.container) {
  width: 100%;
  padding-left: 30px;
}

.questionnaire_body:global(.container) {
  width: 100%;
}
