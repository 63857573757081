.modalStyle:global(.modal-dialog) :global(.modal-body) {
  overflow-y: visible;
}

.productAttributeMappingHeaderTitle {
  font-weight: 350;
}

.productAttributeMappingHeaderSku {
  font-weight: 500;
  opacity: 0.7;
}

.productAttributeMappingContainer:global(.container-fluid) ol {
  padding-left: 0px !important;
}